/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import swal from 'sweetalert2';
import Index from '../views/Index.vue';
import Company from '../views/Company.vue';
import store from '../store/index';
import axios from '@/libs/axios'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/Company.vue'),
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/Service.vue'),
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('@/views/Partner.vue'),
  },
  {
    path: '/process',
    name: 'Process',
    component: () => import('@/views/Process.vue'),
  },
  {
    path: '/solution1',
    name: 'Solution1',
    component: () => import('@/views/Solution1.vue'),
  },
  {
    path: '/solution2',
    name: 'Solution2',
    component: () => import('@/views/Solution2.vue'),
  },
  {
    path: '/product1',
    name: 'Product1',
    component: () => import('@/views/Product1.vue'),
  },
  {
    path: '/product1',
    name: 'Product1',
    component: () => import('@/views/Product1.vue'),
  },
  {
    path: '/product2',
    name: 'Product2',
    component: () => import('@/views/Product2.vue'),
  },
  {
    path: '/product3',
    name: 'Product3',
    component: () => import('@/views/Product3.vue'),
  },
  {
    path: '/product4',
    name: 'Product4',
    component: () => import('@/views/Product4.vue'),
  },
  {
    path: '/product5',
    name: 'Product5',
    component: () => import('@/views/Product5.vue'),
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: () => import('@/views/ContactUs.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue'),
  // },

  { path: '/index.html', redirect: '/' },
  { path: '/service.html', redirect: '/service' },
  { path: '/solution.html', redirect: '/solution' },
  { path: '/contactus.html', redirect: '/contactus' },
  { path: '/company.html', redirect: '/company' },
  { path: '/partner.html', redirect: '/partner' },
  { path: '/process.html', redirect: '/process' },
  { path: '/products.html', redirect: '/product1' },
  { path: '/products-2.html', redirect: '/product2' },
  { path: '/products-3.html', redirect: '/product3' },
  { path: '/products-4.html', redirect: '/product4' },
  { path: '/products-5.html', redirect: '/product5' },
  { path: '/products-6.html', redirect: '/product1' },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


export default router;
