<template>
  <div class="content-Box">
    <link
      rel="stylesheet"
      href="css/cms-index.css"
      type="text/css"
      v-if="$route.path != '/'"
    />

    <header>
      <div class="logo">
        <router-link to="/"><img src="images/logo.png" /></router-link>
      </div>

      <div class="banner" v-if="$route.path == '/'">
        <div id="owl-demo" class="owl-carousel">
          <div class="item">
            <a href="#"><img src="images/idx-ban-1.jpg" /></a>
          </div>
          <div class="item">
            <a href="#"><img src="images/idx-ban-2.jpg" /></a>
          </div>
          <div class="item">
            <a href="#"><img src="images/idx-ban-3.jpg" /></a>
          </div>
          <div class="item">
            <a href="#"><img src="images/idx-ban-4.jpg" /></a>
          </div>
        </div>
      </div>

      <nav>
        <ul class="menu">
          <li>
            <router-link to="/company"
              ><span>About<br />關於我們</span></router-link
            >
          </li>
          <li>
            <router-link to="/service"
              ><span>Service<br />服務說明</span></router-link
            >
          </li>
          <li>
            <router-link to="/product1"
              ><span>Products<br />產品</span></router-link
            >
          </li>
          <li>
            <router-link to="/solution1"
              ><span>Solution<br />金流及管理</span></router-link
            >
          </li>
          <li>
            <router-link to="/contactus"
              ><span>Contact Us<br />聯絡我們</span></router-link
            >
          </li>
        </ul>
      </nav>
    </header>
    <router-view />

    <footer>
      <div class="footer">Topvendex Co., Ltd. All Right Reserved.</div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",

  computed: {},

  data: () => ({}),

  watch: {
    $route(to, from) {
      if (to.path == "/") {
        this.init();
      }
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered
        const owl = $("#owl-demo");
        owl.owlCarousel({
          navigation: true, // Show next and prev buttons
          slideSpeed: 300,
          paginationSpeed: 400,
          singleItem: true,
          autoPlay: false,
          //items : 10, //10 items above 1000px browser width
          itemsDesktop: [1000, 3], //5 items between 1000px and 901px
          itemsDesktopSmall: [900, 3], // betweem 900px and 601px
          itemsTablet: [640, 2], //2 items between 600 and 0
          itemsMobile: [414, 1], // itemsMobile disabled - inherit from itemsTablet option

          // "singleItem:true" is a shortcut for:
          // items : 1,
          // itemsDesktop : false,
          // itemsDesktopSmall : false,
          // itemsTablet: false,
          // itemsMobile : false
        });

        // Custom Navigation Events
        $(".next").click(function () {
          owl.trigger("owl.next");
        });
        $(".prev").click(function () {
          owl.trigger("owl.prev");
        });
        $(".play").click(function () {
          owl.trigger("owl.play", 1000); //owl.play event accept autoPlay speed as second parameter
        });
        $(".stop").click(function () {
          owl.trigger("owl.stop");
        });
      });
    },
  },
};
</script>
