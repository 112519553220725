<template>
  <div id="content">
    <div class="brand-title"><img src="images/brand-title.png" /></div>
    <ul class="brand-bg">
      <li>
        <iframe
          width="876"
          height="300"
          src="https://www.youtube.com/embed/FE1K9dbUdlY"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </li>

    </ul>
    <ul class="link">
      <li>
        <router-link to="solution1"
          ><div class="link-pto fix"><img src="images/idx-pto-1.jpg" /></div>
          <span><b>電子錢包支付 S</b>olution</span></router-link
        >
      </li>
      <li>
        <router-link to="service"
          ><div class="link-pto fix"><img src="images/idx-pto-2.jpg" /></div>
          <span><b>專業 P</b>rofessional</span></router-link
        >
      </li>
      <li>
        <router-link to="partner"
          ><div class="link-pto fix"><img src="images/idx-pto-3.jpg" /></div>
          <span><b>合作夥伴 S</b>upport</span></router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Index",

  computed: {},

  data: () => ({}),

  watch: {},

  mounted() {},

  methods: {},
};
</script>
