import axios from '@/libs/axios'

export default ({
  namespced: true,
  state: {
    counter: 0,
  },
  // setter
  mutations: {
    dCounter(state, rand) {
      state.counter -= rand;
    },
    iCounter(state, rand) {
      state.counter += rand;
    },

  },
  actions: {
    iCounter(state) {
      axios('https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new').then((res) => {
        state.commit('iCounter', res.data);
      });
    },
    dCounter(state) {
      axios('https://www.random.org/integers/?num=1&min=1&max=100&col=1&base=10&format=plain&rnd=new').then((res) => {
        state.commit('dCounter', res.data);
      });
    },
  },
});
