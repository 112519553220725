import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
// import VueSweetalert2 from 'vue-sweetalert2';
// import VuetifyAdmin from 'vuetify-admin';
import _ from 'lodash';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import App from './App.vue';
import router from './router';
// import vuetify from './plugins/vuetify';
import store from './store';
// import axios from './libs/axios';
// import 'sweetalert2/dist/sweetalert2.min.css';// swal css

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueRouter);
// Vue.use(axios);
// Vue.use(VueSweetalert2);
// Vue.use(VuetifyAdmin);
Vue.use(_);
// Vue.use(DatePicker);
// Vue.use(axios);
Vue.prototype.console = console;
Vue.prototype.JMT = (data) => JSON.parse(JSON.stringify(data));
Vue.prototype.env = process.env;
// Vue.prototype.axios = axios;

new Vue({
  router,
  // vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
