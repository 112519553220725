import Vue from 'vue';
import Vuex from 'vuex';
import AuthStore from './AuthStore/index';
import TestStore from './TestStore/index';
// import SweetAlertStore from './SweetAlertStore/index'
import UserListStore from './UserListStore/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    test: TestStore,
    auth: AuthStore,
    // swal: SweetAlertStore,
    userlist: UserListStore,
  },
});
