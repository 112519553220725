import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
const getAccessToken = () => window.localStorage.getItem('token');

axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;

// Or you can use an interceptor if adding tokens etc.
// ======================================
axiosIns.interceptors.request.use(async (config) =>
  // const token = await updateToken();
  // config.headers.common.Authorization = `Bearer ${token}`;
  config);

Vue.prototype.axios = axiosIns;
Vue.prototype.$axios = axiosIns;

export default axiosIns;
