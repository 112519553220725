import axios from '@/libs/axios'

export default ({
  namespced: true,
  state: {
    userlist: [],
  },
  // setter
  mutations: {
    setUserList(state, list) {
      state.userlist = list;
    },
  },
  actions: {
    async getUserList(state) {
      const res = await axios.get('users');
      console.log('getUserList => ', res.data.data);
      state.commit('setUserList', res.data.data);
    },
  },
});
