/* eslint-disable */
import axios from '@/libs/axios'
// import history from 'history';
import swal from 'sweetalert2';
import router from '@/router';

export default ({
  namespced: true,
  state: {
    authorization: '',
    user: null,
    code: null,
    message: '',
    logined: false,

  },
  // setter
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setCode(state, code) {
      state.code = code;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setAuthorization(state, auth) {
      state.authorization = auth;
    },
    setLogined(state, login) {
      state.logined = login;
    },

  },
  actions: {
    async login(state, payload) {
      const getAccessToken = () => window.localStorage.getItem('token');
      try {
        const res = await axios.post('auth/login', payload);

        const { data } = res.data;
        // console.log("res => ", res)
        // console.log("userData => ", data)
        state.commit('setUser', data.user);
        state.commit('setAuthorization', data.Authorization);
        state.commit('setCode', res.data.statusCode);
        state.commit('setMessage', res.data.message);
        state.commit('setLogined', true);

        axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;

        localStorage.setItem('token', data.Authorization);
        localStorage.setItem('roles', JSON.stringify(data.user.rolesArray));
        localStorage.setItem('userId', data.user.id);
        // swal('登入成功','' ,'success')
        
        await swal.fire(
          '登入成功',
          '',
          'success',
        );

        router.push({
          name: 'Home',
        });
      } catch (e) {
        // console.log(e)
        swal.fire(
          '登入失敗',
          '請檢查帳號或密碼，或連繫系統管理者。',
          'error',
        );
      }

      // history.push({
      //     pathname: '/',
      // });
    },

    logout(state) {
      state.commit('setUser', null);
      state.commit('setCode', null);
      state.commit('setMessage', '');
      state.commit('setAuthorization', '');
      state.commit('setLogined', false);
      localStorage.clear();
      router.push({
        name: 'Login',
      });
      swal.fire(
        '已登出',
        '',
        'success',
      );
    },

    async tokenLogin(state) {
      const getAccessToken = () => window.localStorage.getItem('token');
      try {
        const res = await axios.get('auth/token-login', { headers: { Authorization: getAccessToken() } });
        // console.log("token login res => ", res)
        const { data } = res.data;
        // console.log("token login data => ", data)

        state.commit('setUser', data.user);
        state.commit('setAuthorization', data.Authorization);
        state.commit('setCode', res.data.statusCode);
        state.commit('setMessage', res.data.message);

        axios.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;

        localStorage.setItem('token', data.Authorization);
        localStorage.setItem('roles', JSON.stringify(data.user.rolesArray));
        localStorage.setItem('userId', data.user.id);
      } catch (e) {
        console.log(e);
        // this.logout();
        router.push({
          name: 'Login',
        });
        // swal.fire(
        //   '跳轉失敗',
        //   'Token認證失敗',
        //   'error',
        // );
        return e;
      }
    },
  },
  getters: {
    userState: (state) => state.user,
  },
});
